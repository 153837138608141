<template>
  <div class="report-content">
    <div class="report-infobox">
      <div class="__header">
        <h4>Next Schedule</h4>
      </div>
      <div class="__body">
        <ClassCard
          :schedule="schedule"
          v-if="isLoadingNextSchedule == false && schedule.length != 0"
        />
        <circular-loader
          v-else-if="isLoadingNextSchedule == true"
        ></circular-loader>
        <div class="empty-schedule" v-else></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12  today-items">
        <div class="_inner-tabs">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-exam-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-exam"
                type="button"
                role="tab"
                aria-controls="pills-exam"
                aria-selected="true"
              >
                Today Exams
              </button>
            </li>
            <li class="nav-item ms-3" role="presentation ">
              <button
                class="nav-link"
                id="pills-assignment-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-assignment"
                type="button"
                role="tab"
                aria-controls="pills-assignment"
                aria-selected="false"
              >
                Active Assignment
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-exam"
              role="tabpanel"
              aria-labelledby="pills-exam-tab"
            >
              <div class="content">
                <div class="empty-exam" v-if="exams.length == 0">
                  No active exam found!
                </div>
                <div
                  v-else
                  class="exam-list"
                  v-for="(exam, examIndex) in exams"
                  :key="examIndex"
                >
                  <div class="_head">
                    <p class="_title">
                      {{ exam.title }}
                    </p>
                  </div>
                  <div class="_lead">
                    <!-- <p class="_class">
                        {{ exam.program.standard.standard_title }}
                      </p> -->
                    <span class="status green">{{ exam.status }}</span>
                    <div class="cta-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-assignment"
              role="tabpanel"
              aria-labelledby="pills-assignment-tab"
            >
              <div class="content">
                <div class="empty-assignment" v-if="assignments.length == 0">
                  No active assignment found!
                </div>
                <div
                  v-else
                  class="assignment-list"
                  v-for="(assignment, assignmentIndex) in assignments"
                  :key="assignmentIndex"
                >
                  <div class="_head">
                    <p class="_title">
                      {{ assignment.title }}
                      <span class="_subject">{{ assignment.subject }}</span>
                    </p>
                  </div>
                  <div class="_lead">
                    <p class="_program-title">
                      {{ assignment.first_name }}
                    </p>
                    <p class="_submission">
                      Last Date: {{ assignment.last_date }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="report-calender">
          <div class="__header">
            <h4>Attendance</h4>
          </div>
          <div class="__body">
            <div class="calender-wrap">
              <v-calendar
                class="custom-calendar max-w-full"
                :attributes="attendances"
              >
              </v-calendar>

              <div class="stat-wrap">
                <div class="circle-wrap">
                  <circle-progress
                    :percent="percentage"
                    fill-color="#057823"
                    empty-color="#e64f3e"
                  />
                  <p>{{ percentage }}%</p>
                </div>
                <p>
                  <span>Total Classes</span>: <span>{{ totalClasses }}</span>
                </p>
                <p>
                  <span>Presents</span>: <span>{{ totalPresents }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";
import AssignmentService from "@/services/AssignmentService";
import ExamService from "@/services/ExamService";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader.vue";
import CircleProgress from "vue3-circle-progress";
import errorLog from "@/errorLog";
import ClassCard from "@/components/ClassCard.vue";

export default {
  name: "PupaDashboardTab",
  components: {
    CircularLoader,
    CircleProgress,
    ClassCard
  },
  created() {
    this.getAttendance();
    this.getNextSchedule();
    this.getTodayExams();
    this.getActiveAssignment();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  data() {
    //   const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      isLoadingNextSchedule: true,
      schedule: [],
      attendances: [],
      exams: [],
      assignments: [],
      totalClasses: 0,
      totalPresents: 0,
      percentage: 0
    };
  },
  methods: {
    getNextSchedule() {
      this.isLoadingNextSchedule = true;
      ProgramService.getNextSchedule()
        .then(result => {
          if (result.data.status == "success") {
            this.schedule = result.data.next_schedule;
            this.isLoadingNextSchedule = false;
          } else {
            this.isLoadingNextSchedule = false;
          }
        })
        .catch(error => {
          this.isLoadingNextSchedule = false;
          errorLog.log(error);
        });
    },
    getAttendance: function() {
      AuthService.getAttendance()
        .then(result => {
          this.attendances = result.data.attendances;
          this.totalClasses = result.data.total_class;
          this.totalPresents = result.data.total_presents;
          this.percentage = result.data.percentage;
        })
        .catch(error => {
          this.assignmentLoader = false;
          errorLog.log(error);
        });
    },
    getTodayExams: function() {
      ExamService.getTodayExams()
        .then(result => {
          this.exams = result.data.exams;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },

    getActiveAssignment: function() {
      AssignmentService.getAssignments()
        .then(result => {
          this.assignments = result.data.activeAssignments.data;
        })
        .catch(error => {
          this.isNotificationsLoading = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/report-tab.scss";
</style>
